<template>
    
    <div class=" h-screen w-full flex flex-col justify-start items-center">

        <div class="mt-10 w-3/4">
            <div class="flex justify-between items-center">
                <h2 class=" font-semibold tracking-wide text-xl m-2 fonr text-warm-gray-600 dark:text-warm-gray-50">CAAP Exam Scorecard</h2>
                <div class="flex justify-end items-center space-x-2">
                    <button @click="fetchSubjectsWithExamResult" class=" rounded-full bg-warm-gray-200 hover:bg-warm-gray-300 text-warm-gray-500 dark:bg-warm-gray-800 dark:text-warm-gray-600 p-2 dark:hover:bg-warm-gray-700 focus:border-blue-500">
                        <ArrowPathIcon class=" w-5 h-5" />
                    </button>
                </div>
            </div>

            
            <!-- PC -->
            <div class=" hidden md:block overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
                            <thead class="bg-warm-gray-300 dark:bg-warm-gray-800">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                        Subjects
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                        Total Responses
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                        Rating (Ave. in Items)
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                        Ave. Exam Results
                                    </th>
                                    <th scope="col" class=" w-32">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white dark:bg-warm-gray-700 divide-y divide-gray-200 dark:divide-warm-gray-800 dark:divide-opacity-75">
                                <tr v-for="(caapSubRes,i) in subjectsWithExamResult" :key="i" :class="caapSubRes.totalRes == '---' ? 'text-warm-gray-300 dark:text-warm-gray-500' : 'text-warm-gray-600 dark:text-warm-gray-50'">
                                    <td class="px-6 py-4 whitespace-nowrap text-left text-sm flex justify-start" >
                                        <span>{{ caapSubRes.subjectName }}</span>
                                    </td>
                                    <td :class="['px-6 py-4 whitespace-nowrap text-center']"> 
                                        {{ caapSubRes.totalRes }}
                                    </td>
                                    <td :class="['px-6 py-4 whitespace-nowrap text-center']"> 
                                        <span :class="caapSubRes.usersChangedNumItemCount.length == 0 && 'pr-5'">
                                            {{ caapSubRes.aveRating }} / {{ caapSubRes.aveNumItem }} 
                                        </span>
                                        <span v-if="caapSubRes.usersChangedNumItemCount.length > 0">🚩</span>
                                    </td>
                                    <td :class="['px-6 py-4 whitespace-nowrap text-center']"> 
                                        <span :class="caapSubRes.usersChangedPassingScore.length == 0 && 'pr-5'"  >
                                            {{ caapSubRes.aveScore !== '---' ?  parseFloat(caapSubRes.aveScore.toFixed(2)) : '---' }} <span v-if="caapSubRes.aveScore != '---'"> %</span>
                                        </span>
                                        <span v-if="caapSubRes.usersChangedPassingScore.length > 0">🚩</span>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap flex justify-center items-center">
                                        <button v-if="caapSubRes.totalRes == '---' || caapSubRes.totalRes == 0" class="border border-yellow-600 text-yellow-600 rounded px-2 opacity-30" disabled>View details</button>
                                        <router-link v-else :to="'/scorecard/' + caapSubRes.subjectId" tag="button" class="border border-yellow-600 text-yellow-600 rounded px-2">View details</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            
        </div>

    </div>

</template>

<script setup>

import { ref, onMounted } from 'vue';
import { getCaapSubjects, getAllExamResultsComputedData } from '../firebase'
import {ArrowPathIcon} from '@heroicons/vue/24/outline'

const subjectsWithExamResult = ref([])

const fetchSubjectsWithExamResult = async () => {

    subjectsWithExamResult.value = [];

    const [subjects, examResults] = await Promise.all([
        getCaapSubjects(),
        getAllExamResultsComputedData(true) // true <= is to return as object
    ])

    subjects.forEach((subject)=>{

        let data = {
            subjectId: subject.id,
            subjectName: subject.data.name,
            totalRes: '---',
            aveRating: '---',
            aveNumItem: '---',
            aveScore: '---',
            usersChangedNumItemCount: [],
            usersChangedPassingScore: []
        }

        if (examResults[subject.id]) {
            
            data.totalRes = examResults[subject.id].totalRes
            data.aveRating = examResults[subject.id].totalRes > 0 ? Math.ceil(examResults[subject.id].totalNumItemsFromApp / examResults[subject.id].totalRes) : '---'
            data.aveNumItem = examResults[subject.id].totalRes > 0 ? Math.ceil(examResults[subject.id].totalNumItemsInExam / examResults[subject.id].totalRes) : '---'
            data.aveScore = examResults[subject.id].totalRes > 0 ? examResults[subject.id].totalScore / examResults[subject.id].totalRes : '---'
            data.usersChangedNumItemCount = examResults[subject.id].usersChangedNumItemCount
            data.usersChangedPassingScore = examResults[subject.id].usersChangedPassingScore

        }

        subjectsWithExamResult.value.push(data)

    })
    
    // rearrange the subject to display based on the test result template
    const order = [
            'air-law',
            'nat-sci',
            'aircraft-engineering',
            'aircraft-maintenance',
            'human-performance',
            'airframe-rating',
            'powerplant-rating',
            'avionics-rating'
            ];
    subjectsWithExamResult.value.sort((a, b) => {
        let indexA = order.indexOf(a.subjectId);
        let indexB = order.indexOf(b.subjectId);
        return indexA - indexB;
    });

}

onMounted(async ()=>{
    await fetchSubjectsWithExamResult();
})

</script>